import NotificationsIcon from '@mui/icons-material/Notifications';
import RefreshIcon from '@mui/icons-material/Refresh';
import { Badge, Box, Divider, IconButton, Paper, Popover, Typography } from "@mui/material";
import ArchivedCrashNotification from "components/Notification/ArchivedCrashNotification";
import NoNewNotifications from "components/Notification/NoNewNotifications";
import { archivedCrashNotificationDto, NotificationDto, NotificationType } from "dto/notificationDto";
import { useEffect, useState } from "react";
import InfiniteScroll from 'react-infinite-scroll-component';
import { GetListOfArchivedCrasheshWithThreshold } from "services/crashService";
import { selectAuth } from "slices/authSlice";
import { clearNotifications, selectNotifications, setNotifications } from "slices/notificationSlice";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { Log } from "utilities/helper";
import Error from "components/Notification/Error";
import Loading from "components/Notification/Loading";
import NothingMoreToShow from "components/Notification/NothingMoreToShow";
import { Result } from "dto/apiResultDto";
import { RemoveAllNotificationButton } from './RemoveAllNotificationButton';
import { countUnreadNotification, getNotifications, removeAllCrashNotification } from 'src/services/notificationService';
import { LogLevel } from '@azure/msal-browser';
import JiraCrashNotification from './JiraCrashNotification';

export default function NotificationPanel() {
    const dispatch = useAppDispatch();
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [hasMore, setHasMore] = useState(false);
    const [currentPageNumber, setCurrentPageNumber] = useState(1);
    const [totalNotificationCount, setTotalNotificationCount] = useState(0);
    const [loading, setLoading] = useState(false);
    const [errorOccured, setErrorOccured] = useState(false);
    const auth = useAppSelector(selectAuth);
    const itemPerPage = 6;

    var notifications = useAppSelector(selectNotifications).List;
    const [isReadAll, setIsReadAll] = useState(true);
    const [unreadCount, setUnreadCount] = useState(0);
    const [invisible, setInvisible] = useState(false);

    const loadArchivedNotificationData = () => {
        let page = notifications.length > 0 ? currentPageNumber : 1;
        getNotifications(auth.authToken, page, itemPerPage).then((response) => {
            if (response[0]) {
                var result: Result = response[0];

                if (result.success) {
                    var newNotification: NotificationDto[] = result.data.map((item: archivedCrashNotificationDto) => {
                        return {
                            type: NotificationType.Manual,
                            item: item
                        };
                    })

                    dispatch(setNotifications(notifications.concat(newNotification)));
                    setCurrentPageNumber(page + 1);
                    if (result.currentPageNumber && result.itemPerPage && result.totalCount) {
                        setHasMore(result.currentPageNumber * result.itemPerPage < result.totalCount);
                    }
                    setErrorOccured(false);
                    setTotalNotificationCount(result.totalCount);
                } else {
                    Log(result.message);
                    setErrorOccured(true);
                }
            } else {
                var error = response[1];
                Log(error.message);
                setErrorOccured(true);
            }
            setLoading(false);
        });

        getNumberOfUnreadNotification();
    }
    
    const loadNotifications = () => {
        loadArchivedNotificationData();
    }

    const refreshPanel = () => {
        notifications = [];
        dispatch(clearNotifications());
        setLoading(true);
        loadNotifications();
    }

    const clearNotification = () => {
        notifications = [];
        dispatch(clearNotifications());
        getNumberOfUnreadNotification();
    }

    useEffect(() => {
        setLoading(true);
        loadNotifications();
    }, [auth.authToken]);

    const handleNotificationIconClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleNotificationPanelClose = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(null);
    };

    const getNumberOfUnreadNotification = () => {
        countUnreadNotification(auth.authToken).then((response) => {
            if(response[0] && response[0].success) {
                var result: Result = response[0];
                if (result.data > 0) {
                    setIsReadAll(false);
                    setUnreadCount(result.data);
                    setInvisible(false);
                }
                else {
                    setIsReadAll(true);
                    setUnreadCount(result.data);
                    setInvisible(true);
                }
            }
            else {
                var error = response[0] ? response[0].message : response[1];
                Log(error, LogLevel.Error);
            }
        })
    }

    return (
        <>
            <IconButton color={ isReadAll ? "secondary" : "primary"} aria-label="delete"  onClick={handleNotificationIconClick}>
                <Badge badgeContent={unreadCount} invisible={invisible} color='primary'>
                    <NotificationsIcon />
                </Badge>
            </IconButton>
            <Popover
                anchorEl={anchorEl}
                onClose={handleNotificationPanelClose}
                disableScrollLock={true}
                open={Boolean(anchorEl)}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left"
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "right"
                }}
                PaperProps={{
                    style: { minWidth: "20%" },
                }}
                style={{ height: "50%" }}
            >
                <Box
                    sx={{ p: 2 }}
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                >
                    <Typography variant="h4">
                        Notifications
                        <Typography variant="body1">
                            {totalNotificationCount} notification{totalNotificationCount > 1 ? "s" : ""}
                        </Typography>
                    </Typography>
                    <Typography
                        display="flex"
                        alignItems="end"
                        justifyContent="space-between">
                        <RemoveAllNotificationButton triggerUpdateData={clearNotification} />
                        <IconButton onClick={refreshPanel}>
                            <RefreshIcon />
                        </IconButton>
                    </Typography>
                </Box>
                <Divider />

                <Paper id={"scrollView"} style={{ maxHeight: "300px", overflowY: "scroll" }}>
                    {notifications.length > 0 ? (
                        <InfiniteScroll
                            dataLength={notifications.length}
                            next={loadNotifications}
                            hasMore={hasMore}
                            loader={errorOccured ? <Error /> : <Loading />}
                            endMessage={<NothingMoreToShow />}
                            scrollableTarget="scrollView"
                        >
                            {notifications.map((notificationItem, index) => {
                                switch (notificationItem.item.type) {
                                    case NotificationType.Manual:
                                        return <ArchivedCrashNotification key={index} item={notificationItem.item as archivedCrashNotificationDto} triggerUpdateData={getNumberOfUnreadNotification} />;
                                    case NotificationType.Jira:
                                        return <JiraCrashNotification key={index} item={notificationItem.item as archivedCrashNotificationDto} triggerUpdateData={getNumberOfUnreadNotification} />;
                                    default:
                                        return null;
                                }
                            })}
                        </InfiniteScroll>)
                        :
                        <Box>
                            {loading ? <Loading /> : <NoNewNotifications />}
                        </Box>
                    }
                </Paper>
            </Popover>
        </>
    );
}