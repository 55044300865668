import { Box, Chip, Grid, Link, ListItem, Typography } from "@mui/material";
import ViewDetailsButton from "components/CrashDetails/ViewDetailsButton";
import { ArchiveButton } from "components/Crash/ArchiveButton";
import { ArchiveStatus } from "dto/crashRequestDto";
import { deleteNotification, markNotification } from "slices/notificationSlice";
import { useAppDispatch } from "store/hooks";
import { formatDistance, subDays } from "date-fns";
import { RemoveNotificationButton } from "./RemoveNotificationButton";
import { MarkButton } from "./MarkNotificationButton";
import { archivedCrashNotificationDto } from "src/dto/notificationDto";

interface propsType {
    item: archivedCrashNotificationDto,
    triggerUpdateData: any
}
export default function ArchivedCrashNotification(notificationItem: propsType) {
    const dispatch = useAppDispatch();

    const onDelete = () => {
        dispatch(deleteNotification(notificationItem.item.id));
        notificationItem.triggerUpdateData(); // Update count unread
    }

    const onMark = () => {
        dispatch(markNotification({crashId: notificationItem.item.id, isRead: !notificationItem.item.isRead}));
        notificationItem.triggerUpdateData(); // Update count unread
    }

    return (
        <Box
            sx={{ p: 2, minWidth: 300, display: { xs: 'block', sm: 'flex' },
            '&:hover': {
                bgcolor: '#ecf5fc'
          }, }}
        >
            <Grid container spacing={1} justifyContent={"space-between"}>
                <Grid item md={8}>
                    <Box display="flex" justifyContent="space-between">
                        <Typography sx={{ fontWeight: 'bold' }}>
                            <Link href={window.location.origin + "/Crash/" + notificationItem.item.crashId + "?markAsRead=true"} underline="hover">
                                Crash - {notificationItem.item.crashId}
                            </Link>
                        </Typography>
                    </Box>
                    <Typography
                        component="span"
                        variant="body2"
                        color="text.secondary"
                    >
                        Count of Similar Crash: {notificationItem.item.countOfSimilarCrash}
                    </Typography>
                    <Box display="flex" justifyContent="space-between">
                        <Typography
                            component="span"
                            variant="body2"
                            color="text.secondary"
                        >
                            Archived by Jira status
                        </Typography>
                    </Box>
                </Grid>
                <Grid item md={2} alignSelf={"center"} pr={1}>
                    {/* <ArchiveButton id={notificationItem.item.id} type={ArchiveStatus.Archived} triggerUpdateData={onDelete} /> */}
                    <RemoveNotificationButton crashId={notificationItem.item.crashId} triggerUpdateData={onDelete} />
                </Grid>
                <Grid item md={2} alignSelf={"center"} pr={1}>
                    <MarkButton crashId={notificationItem.item.crashId} isRead={notificationItem.item.isRead} triggerUpdateData={onMark} />
                </Grid>
            </Grid>
        </Box>
    );
}