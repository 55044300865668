export enum NotificationType {
	Manual = 0,
	Jira =1
}
export interface NotificationDto {
	// type: NotificationType;
	item: archivedCrashNotificationDto | any;
	isRead: boolean;
}

export interface RemoveCrashNotificationDto {
	crashId: number;
}

export interface MarkCrashNotificationDto {
	crashId: number;
	isRead: boolean;
}

export interface archivedCrashNotificationDto {
	id: number;
	crashId: number;
	countOfSimilarCrash: number;
	isRead: boolean;
	type: NotificationType;
}

